class AidePiecesService {
  #piecesService;

  constructor(piecesService) {
    this.#piecesService = piecesService;
  }

  /**
   * Check documents of given aide, depending on teleservice configuration
   *
   * @param {string} aideId - Aide id used as entity-link for pieces
   * @param {object} teleservice - Full teleservice, containing aide configuration
   * @param {string | null} contributionReference - Reference of the current contribution, if any
   * @param {string[] | undefined} excludedPiecesReferences references of pieces to exclude from the check
   * @returns {Promise<object>}
   */
  checkAideDocuments(aideId, teleservice, contributionReference = null, excludedPiecesReferences) {
    let piecesToCheckFilter = '@.fonction == "depot"';

    if (excludedPiecesReferences && excludedPiecesReferences.length > 0) {
      piecesToCheckFilter += excludedPiecesReferences
        .map((excludedPieceReference) => ` && @.reference != "${excludedPieceReference}"`)
        .join('');
    }

    const pathPiecesProperties = [`$.pieces[?(${piecesToCheckFilter})]`];

    if (teleservice.workflow.simple?.pageDomiciliationBancaire?.actif) {
      pathPiecesProperties.push('$.domiciliationBancaire.pieces[?(@.fonction == "depot")]');
    }

    const entityLink = contributionReference ? `${aideId}?contribution=${contributionReference}` : aideId;
    return this.#piecesService.checkDocumentsPieces(entityLink, pathPiecesProperties);
  }

  /**
   * Return the references of depot pieces that are set on the aide but hidden
   *
   * @param {object} aide aide
   * @param {object} conditionEvaluationScope scope to use to evaluate the pieces conditions
   * @returns {string[]} Array of references of hidden pieces
   */
  getHiddenPiecesReferences(aide, conditionEvaluationScope) {
    const depotPieces = aide.pieces?.filter((piece) => piece.fonction === 'depot') ?? [];
    const hiddenPieces = depotPieces.filter(
      (piece) =>
        piece.conditionAffichage &&
        !this.#piecesService.evalConditionPiece(conditionEvaluationScope, piece, 'conditionAffichage')
    );
    return hiddenPieces.map((piece) => piece.reference);
  }
}

AidePiecesService.$inject = ['piecesService'];

angular.module('aides.services').service('aidePiecesService', AidePiecesService);
